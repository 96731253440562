import React from "react"
import clsx from "clsx"

import ImageBlock from "./image-block"
import TextBlock from "./text-block"
import { RichTextBlock } from "prismic-reactjs"

interface ContentBlockProps {
  header: string
  text: RichTextBlock[]
  justifyText?: boolean
  imageUrl: string
  imageWidth: string
  imageLink?: string
  rowsOnLgScreen?: boolean
  rowReversed?: boolean //reverse flex order on md or larger screens
  colReversed?: boolean //reverse flex col order
  imageMd50?: boolean //If image should be set to 50% on medium or larger screens
  imageMdMaxHeight?: string // set max height for image on medium or larger screens
}

const ContentBlock: React.FC<ContentBlockProps> = ({
  header,
  text,
  imageUrl,
  imageWidth,
  imageLink,
  rowReversed,
  colReversed,
  justifyText,
  imageMd50,
  imageMdMaxHeight,
  rowsOnLgScreen,
}) => {
  return (
    <div
      className={`flex ${colReversed ? "flex-col-reverse" : "flex-col"} ${
        rowReversed
          ? `${rowsOnLgScreen ? "lg:flex-row-reverse" : "xl:flex-row-reverse"}`
          : `${rowsOnLgScreen ? "lg:flex-row" : "xl:flex-row"}`
      }`}
    >
      <div className="flex-1">
        <ImageBlock
          imageUrl={imageUrl}
          width={imageWidth}
          imageMd50={imageMd50 ? imageMd50 : false}
          mdMaxHeight={imageMdMaxHeight}
          imageLink={imageLink}
        />
      </div>
      <div className="flex flex-col justify-center flex-1">
        <TextBlock
          header={header}
          prismicRichText={text}
          justifyText={justifyText ? true : false}
        />
      </div>
    </div>
  )
}

export default ContentBlock
