import React from "react"
import clsx from "clsx"

interface ImageBlockProps {
  imageUrl: string
  width: string
  imageLink?: string
  alt?: string
  imageMd50?: boolean
  mdMaxHeight?: string
}

const ImageBlock: React.FC<ImageBlockProps> = ({
  imageUrl,
  width,
  imageLink,
  alt,
  imageMd50,
  mdMaxHeight,
}) => {
  let _width = ""
  let pyClassName = "py-10"
  switch (width) {
    case "50%":
      _width = "w-1/2 sm:w-4/12"
      break
    case "90%":
      _width = "w-11/12 sm:w-9/12"
      break
    case "100%":
      _width = "w-full"
      pyClassName = ""
      break
  }

  let _maxHeight = ""
  switch (mdMaxHeight) {
    case "200px":
      _maxHeight = "md:max-h-200" // this needs to be configured in tailwind.config.js
  }

  if (imageMd50) {
    _width += " md:w-1/2"
  }

  return (
    <div
      className={`w-full h-full flex justify-center items-center ${
        imageLink ? "cursor-pointer" : ""
      } ${pyClassName} ${width === "100%" ? "" : "bg-black"}`}
      onClick={() => (imageLink ? window.open(imageLink, "_blank") : null)}
    >
      <div className={`${_width} `}>
        <img
          src={imageUrl}
          alt={alt || ""}
          className={clsx(
            `mx-auto w-full h-auto object-contain first-letter:${_maxHeight} ${_maxHeight}`
          )}
        />
      </div>
    </div>
  )
}

export default ImageBlock
