import React from "react"
import { RichText, RichTextBlock } from "prismic-reactjs"

interface TextBlockProps {
  header: string
  prismicRichText: RichTextBlock[]
  justifyText?: boolean
  justifyHeader?: boolean
}

const TextBlock: React.FC<TextBlockProps> = ({
  header,
  prismicRichText,
  justifyText,
  justifyHeader,
}) => {
  return (
    <div
      className={`w-full bg-white py-8 md:py-12 px-8 sm:px-10 md:px-6 lg:px-20 text-black ${
        justifyHeader ? "text-justify" : "text-center"
      }`}
    >
      <h3
        className={`text-2xl xl:text-4xl font-semibold ${
          header.length > 0 ? "pb-8 md:pb-4" : "p-0"
        }`}
      >
        {header}
      </h3>
      <div
        className={`leading-normal text-base xl:text-lg whitespace-pre-line ${
          justifyText ? "text-justify" : "text-center"
        }`}
      >
        <RichText
          render={prismicRichText}
          elements={{
            hyperlink: ({ children, data }) => {
              return (
                <a key={data.url} href={data.url} target="_blank">
                  {children}
                </a>
              )
            },
          }}
        />
      </div>
    </div>
  )
}

export default TextBlock
