import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

import ContentBlock from "../components/content-block"

const ProjectsPage: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "how-we-work",
        page_path: "/how-we-work",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])

  const data = useStaticQuery(graphql`
    query {
      prismicHowWeWorkPage {
        data {
          body {
            ... on PrismicHowWeWorkPageDataBodyContentBlock {
              slice_type
              primary {
                header {
                  text
                }
                text {
                  richText
                }
                image {
                  alt
                  url(imgixParams: { width: 1280 })
                }
                image_width
              }
            }
          }
        }
      }
    }
  `)
  const slices = data.prismicHowWeWorkPage.data.body

  return (
    <Layout>
      {slices.map((slice, i) => {
        if (slice.slice_type === "content_block") {
          return (
            <ContentBlock
              key={i}
              header={slice.primary.header.text}
              text={slice.primary.text.richText}
              imageUrl={slice.primary.image.url}
              imageWidth={slice.primary.image_width}
              imageMd50
              imageMdMaxHeight={"200px"}
              rowReversed={i % 2 == 0}
            />
          )
        } else return null
      })}
    </Layout>
  )
}

export default ProjectsPage
